/**
* Todos los estilos que quieras poner con css puro
*/
/* KANIT */
@font-face {
  font-family: 'Kanit';
  font-weight: 800;
  src: url('./../fonts/kanit/Kanit-ExtraBold.eot');
  src: url('../fonts/kanit/Kanit-ExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/kanit/Kanit-ExtraBold.otf') format('opentype'),
    url('../fonts/kanit/Kanit-ExtraBold.svg') format('svg'),
    url('./../fonts/kanit/Kanit-ExtraBold.ttf') format('truetype'),
    url('../fonts/kanit/Kanit-ExtraBold.woff') format('woff'),
    url('../fonts/kanit/Kanit-ExtraBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Kanit';
  font-weight: 700;
  src: url('./../fonts/kanit/Kanit-Bold.eot');
  src: url('../fonts/kanit/Kanit-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/kanit/Kanit-Bold.otf') format('opentype'),
    url('../fonts/kanit/Kanit-Bold.svg') format('svg'),
    url('../fonts/kanit/Kanit-Bold.ttf') format('truetype'),
    url('../fonts/kanit/Kanit-Bold.woff') format('woff'),
    url('../fonts/kanit/Kanit-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Kanit';
  font-weight: 600;
  src: url('../fonts/kanit/Kanit-SemiBold.eot');
  src: url('../fonts/kanit/Kanit-SemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/kanit/Kanit-SemiBold.otf') format('opentype'),
    url('../fonts/kanit/Kanit-SemiBold.svg') format('svg'),
    url('../fonts/kanit/Kanit-SemiBold.ttf') format('truetype'),
    url('../fonts/kanit/Kanit-SemiBold.woff') format('woff'),
    url('../fonts/kanit/Kanit-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Kanit';
  font-weight: 500;
  src: url('../fonts/kanit/Kanit-Medium.eot');
  src: url('../fonts/kanit/Kanit-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/kanit/Kanit-Medium.otf') format('opentype'),
    url('../fonts/kanit/Kanit-Medium.svg') format('svg'),
    url('../fonts/kanit/Kanit-Medium.ttf') format('truetype'),
    url('../fonts/kanit/Kanit-Medium.woff') format('woff'),
    url('../fonts/kanit/Kanit-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Kanit';
  font-weight: 300;
  src: url('../fonts/kanit/Kanit-Light.eot');
  src: url('../fonts/kanit/Kanit-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/kanit/Kanit-Light.otf') format('opentype'),
    url('../fonts/kanit/Kanit-Light.svg') format('svg'),
    url('../fonts/kanit/Kanit-Light.ttf') format('truetype'),
    url('../fonts/kanit/Kanit-Light.woff') format('woff'),
    url('../fonts/kanit/Kanit-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Kanit';
  font-weight: 200;
  src: url('../fonts/kanit/Kanit-ExtraLight.eot');
  src: url('../fonts/kanit/Kanit-ExtraLight.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/kanit/Kanit-ExtraLight.otf') format('opentype'),
    url('../fonts/kanit/Kanit-ExtraLight.svg') format('svg'),
    url('../fonts/kanit/Kanit-ExtraLight.ttf') format('truetype'),
    url('../fonts/kanit/Kanit-ExtraLight.woff') format('woff'),
    url('../fonts/kanit/Kanit-ExtraLight.woff2') format('woff2');
}

@font-face {
  font-family: 'Bowlby One';
  font-weight: normal;
  src: url('../fonts/bowlby/BowlbyOneSC-Regular.eot');
  src: url('../fonts/bowlby/BowlbyOneSC-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/bowlby/BowlbyOneSC-Regular.otf') format('opentype'),
    url('../fonts/bowlby/BowlbyOneSC-Regular.svg') format('svg'),
    url('../fonts/bowlby/BowlbyOneSC-Regular.ttf') format('truetype'),
    url('../fonts/bowlby/BowlbyOneSC-Regular.woff') format('woff'),
    url('../fonts/bowlby/BowlbyOneSC-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  src: url('../fonts/montserrat/Montserrat-ExtraBold.eot');
  src: url('../fonts/montserrat/Montserrat-ExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-ExtraBold.otf') format('opentype'),
    url('../fonts/montserrat/Montserrat-ExtraBold.svg') format('svg'),
    url('../fonts/montserrat/Montserrat-ExtraBold.ttf') format('truetype'),
    url('../fonts/montserrat/Montserrat-ExtraBold.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-ExtraBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  src: url('../fonts/montserrat/Montserrat-Bold.eot');
  src: url('../fonts/montserrat/Montserrat-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-Bold.otf') format('opentype'),
    url('../fonts/montserrat/Montserrat-Bold.svg') format('svg'),
    url('../fonts/montserrat/Montserrat-Bold.ttf') format('truetype'),
    url('../fonts/montserrat/Montserrat-Bold.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url('../fonts/montserrat/Montserrat-Medium.eot');
  src: url('../fonts/montserrat/Montserrat-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-Medium.otf') format('opentype'),
    url('../fonts/montserrat/Montserrat-Medium.svg') format('svg'),
    url('../fonts/montserrat/Montserrat-Medium.ttf') format('truetype'),
    url('../fonts/montserrat/Montserrat-Medium.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url('../fonts/montserrat/Montserrat-Regular.eot');
  src: url('../fonts/montserrat/Montserrat-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/montserrat/Montserrat-Regular.otf') format('opentype'),
    url('../fonts/montserrat/Montserrat-Regular.svg') format('svg'),
    url('../fonts/montserrat/Montserrat-Regular.ttf') format('truetype'),
    url('../fonts/montserrat/Montserrat-Regular.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-Regular.woff2') format('woff2');
}
/*
@font-face {
  font-family: 'Encode Sans';
  font-weight: 200;
  src: url('../fonts/encode-sans/EncodeSans-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Encode Sans';
  font-weight: 300;
  src: url('../fonts/encode-sans/EncodeSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Encode Sans';
  font-weight: normal;
  src: url('../fonts/encode-sans/EncodeSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Encode Sans';
  font-weight: 600;
  src: url('../fonts/encode-sans/EncodeSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Encode Sans';
  font-weight: 700;
  src: url('../fonts/encode-sans/EncodeSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Encode Sans';
  font-weight: 800;
  src: url('../fonts/encode-sans/EncodeSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Encode Sans';
  font-weight: 900;
  src: url('../fonts/encode-sans/EncodeSans-ExtraBold.ttf') format('truetype');
}
*/
.logo-jm {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  height: 60px;
  width: 120px;
}

.root-pages {
  min-height: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #f4f6f8;
}

.paginador {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  justify-content: center;
  font-size: 14px;
}

.arrows-paginador {
  font-size: 14px !important;
}

/* CKEDITOR */
h1.rea,
.ck.ck-button.ck-heading_heading1_rea {
  font-family: 'Bowlby One';
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 20px;
  font-size: 3rem;
  line-height: 1.2;
}

h2.rea2,
.ck.ck-button.ck-heading_heading2_rea2 {
  font-family: 'Bowlby One';
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 20px;
  font-size: 1.7rem;
  line-height: 30px;
}

h3.rea3,
.ck.ck-button.ck-heading_heading3_rea3 {
  font-family: 'Bowlby One';
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
  font-size: 1rem;
  line-height: 1.8rem;
}

.ck.ck-dropdown__panel {
	max-height: 160px; /* or anything else, more likely ~300px or so */
	overflow-y: auto;
}


.circle {
    padding: 10px 11px;
    height: 2px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    width: 2px;
}

.green {
    background: green;
}

.red {
    background: red;
}

.jc-c {
    justify-content: center;
}

.ta-c {
  text-align: center;
}

.ta-l {
  text-align: left;
}

.flex-container {
  flex-direction: row;
}

.texto-truncate > * {
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
}

.img-select.val-error {
  border:1px solid #f44336;
  margin:0 1rem;
  border-radius:4px;
}
.img-input .val-error-Txt {margin-left: 1rem; color: #f44336;}