@media only screen and (max-width: 1288px) {
	.main-img {padding: 50px 50px 30px 400px!important;}
}

@media only screen and (min-width: 1025px) {
    .jm-buscador {max-height: 366px;}
    .icono-buscador {height: 50px; width: 50px;}
    .card-search {height: 400px;}
    .icono-home-xl {height: 250px;width: 250px;margin-top: 0px;}
    .icono-home-lg {height: 150px;width: 150px;}
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .jm-buscador {margin-top: 7px!important; max-height: 339px;}
    .card-search {height: 400px!important;}
    .icono-home-xl {height: 100px;width: 100px;margin-top: 0px;}
    .icono-home-lg {height: 160px;width: 160px;}
    .max-ch {display: -webkit-box;-webkit-line-clamp: 5!important;-webkit-box-orient: vertical;overflow: hidden;}
    .icono-buscador {width: 70px!important; height: 70px!important; border-radius: 5px!important;margin-top:30px!important;}
}

@media screen and (max-width: 768px) {
    .jm-buscador {margin-top: 95px;width: 90%;left: 5%;}
    .icono-buscador {width:50px;height: 50px;}
    .card-search {padding:20px;height: 360px;}
    .bubble {padding: 4px;}
    .icono-home-xl {height: 130px;width: 130px;}
    .icono-home-lg {height: 120px;width: 120px;}
    .input-group-lg > .form-control, .input-group-lg > .custom-select, .input-group-lg > .input-group-prepend > .input-group-text, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-append > .btn {font-size: 13px;padding: 6px;max-height: 40px;}
    .max-ch {display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;}
    .main-img {padding: 50px 20px 30px 20px!important; margin-bottom: 40px; margin-top: 70px;}
}

.main-img {
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: auto;
    margin-left: 0px;
    position: relative;
    padding: 50px 100px 50px 400px;
    color: white;
	min-height: 380px;
}

.jm-buscador {
    position: absolute;
    margin-top: 0;
    left: 30px;
    width: 350px;
    bottom: 0;
}

.bg-card {
	color: #fff;
	transition: all .3s ease-in-out;
}

.bg-card:hover{
	transform: scale(1.03);
}

.jm-bgk-img2 {
	background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 0px;
    padding: 10px 0;
	height: 370px;
}


.max-ch {
	display: -webkit-box;
  	-webkit-line-clamp: 4;
  	-webkit-box-orient: vertical;
  	overflow: hidden;
}

.card-search {
	width: 100%;
	border-radius: 10px;
	margin-top: 40px;
	margin-bottom: 30px;
	padding: 20px;
    height: 410px;
}

.jm-bgk-img {
	background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 0px;
    padding: 20px;
	height: 400px;
}

.bubble {
	height: auto;
	width: 100%;
	background: white;
	display: block;
	margin: 30px 0px 0px 0px;
	border-radius: 5px;
	text-align: left;
	padding: 10px;
}

.iconos-home-recursos {
    color: #fff;
    transition: all 0.3s ease-in-out;
}

.iconos-home-recursos:hover {
    transform: scale(1.1);
}


.card-footer-transparent {
    background-color: transparent;
    margin-top: -30px;
    padding: 10px;
}

.card-link, .card-link:hover, .card-link:focus, .card-link:active {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
}

.card {
    border-radius: 10px !important;
}

.icono-home-xl {
    background-position: 50%;
    background-size: contain;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0;
    background-repeat: no-repeat;
}

.icono-home-lg {
	background-position: center center;
	background-size:contain;
	position: relative;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 0px;
	background-repeat: no-repeat;
}

.card-body {
    padding: 0.8rem 1.2rem;
}

.componenteTexto{
    font-weight: 300;
    font-size: 17px;
    text-align: justify;
}

@media screen and (min-width: 1200px){
    .modal-xl {
        max-width: 95%!important;
    }
}

@media screen and (min-width: 992px){
    .modal-xl {
        max-width: 95%!important;
    }
}

li.nav-item.active:after {
    border-bottom: 2px solid #fff;
    content: "";
    display: block;
    margin: -10px auto;
    width: 62%;
    padding-top: 0px;
}

.navbar-dark .navbar-nav .nav-link {
	font-weight: 900;
}

li.nav-item.active:after {
    border-bottom: 2px solid #fff;
    content: "";
    display: block;
    margin: -10px auto;
    width: 50%;
    padding-top: 0px;
    margin-bottom: 10px;
    left: 30px;
    position: absolute;
}

.logo-jm {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	padding-left: 0;
}

#FormNoticia .MuiFormLabel-root.Mui-error { color:#546e7a; }

.agrupador-item-btns {
    position: relative;
    display: flex;
    gap:3px;
    float: right;
    margin-top: -30px;
}
.item-btn {
    width: 29px;
    background: #4f31ff;
    padding: 0 6px;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    border: none;
}
.item-btn[disabled] {background-color: #a899ff; cursor: unset; pointer-events: none;}
.item-btn svg {margin-top:-3px;}
.item-btn.item-btn-delete
{
    background: rgb(255, 23, 23);
}
.add-item-agrupador {
    border: none;
    background: #3f51b5;
    padding: 6px 1rem;
    font-weight: 400;
    color: white;
    border-radius: 4px;
}



#uploadingModal {
    border: 5px solid #3f51b5;
    background: white;
    border-radius: 6px;
    padding: 1rem;
    margin: auto;
}
#uploadingModal::backdrop {background: rgb(218 218 218 / 47%);}
#uploadingModal div { margin:0 auto 0 auto; width:40px }
#uploadingModal h5 {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    text-align: center;
    margin-bottom: 20px;
    width: 300px;
}